import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { iconos } from '../../iconos/iconos';
import { IIconos } from '../../svg/svgEstandar/modelo/svg';

@Injectable({
  providedIn: 'root'
})
export class SvgService {

  listaSvg = new BehaviorSubject<IIconos[]>([]);
    listaSvg$ = this.listaSvg.asObservable();

    constructor(private _httpService: HttpClient) { }

    private transformarBlob(svg: IIconos): string {
      svg.Svg = svg.Svg.replace('\n', '');
      const blob = new Blob([svg.Svg], {
        type: 'image/svg+xml',
      });
      const url = URL.createObjectURL(blob);
      //url = url.replace('blob:', "");
      return url;
    }

    private obtenerArchivo(): string[] {
      let archivo: string[] = [];
      archivo = iconos.map((x: any) => {
        return x.url;
      });
      return archivo;
    }

    public async obtenerIconos() {
      const listaSvg: IIconos[] = [];
      const body = {
        Archivo: this.obtenerArchivo(),
      };
      await lastValueFrom(
        this._httpService.post<IIconos[]>('/api/iconos/diccionario', body)).then((svg_iconos: IIconos[]) => {
        svg_iconos.forEach((svg_icon) => {
          const archivoSvg = iconos.find((icon: any ) => icon.url == svg_icon.Nombre);
          let urlSVG = '';
          if (archivoSvg?.estandar) {
            urlSVG = this.transformarBlob(svg_icon);
          }
          listaSvg.push({
            Id: svg_icon.Id,
            Nombre: svg_icon.Nombre,
            Svg: svg_icon.Svg,
            Url: urlSVG,
          });
        });
      });
      this.listaSvg.next(listaSvg);
    }
}
