import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IIconos, image, svg, texto } from './modelo/svg';
import { obtenerVariablesCSS } from '../../utiles/variablesScss';
import { SvgService } from '../../services/svg/svg.service';

@Component({
  selector: 'destinux-svgEstandar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './svgEstandar.component.svg',
  styleUrls: ['./svgEstandar.component.scss'],
})
export class svgEstandarComponent {
  //#region inputs
  /** indica la cantidad de paths que tendrá el SVG */
  @Input() cantidadPath: number = 3;
  /** la url donde se encuentra el icono a mostrar */
  @Input() urlIcono: string = '';
  /** si se desea agregar texto se debe indicar cual es el texto a mostrar  */
  @Input() texto: texto | null | undefined = null;
  /** en caso de querer indicar el color del texto ingresado anteriormente  */
  @Input() colorTexto: string = obtenerVariablesCSS('--textoLista-boton-primario');

  @Input() disabled: boolean = false;
  /** en caso de necesitar modificar colores fuera de los que están por defectos, estos se deben realizar mediante este parámetro  */
  @Input() set urlsPath(val: svg[] | null) {
    this.mostrar = false;
    this.#urlsPath = val;
    this.transformarURL();
  }
  get urlsPath() {
    return this.#urlsPath;
  }
  /** permite ingresar una imagen, indicando posición y tamaño */
  @Input() urlImg: image | null = null;
  /** anchura del svg  */
  @Input() width: string = '100%';
  /** altura del svg  */
  @Input() height: string = '100%';
  /** posición  y dimensiones del svg */
  @Input() viewBox: string = '0 0 48 48';
  /** clase que se quiere agregar al svg  */
  @Input() classSvg: string = '';
  /** clase que se le quiere agregar a la imagen */
  @Input() classImg: string = '';
  /** clase que se le quiere agregar al texto */
  @Input() classTexto: string = '';
  //#endregion

  public url: string = '';
  #urlsPath: svg[] | null = null;
  public mostrar: boolean = false;

  constructor(private _svgService: SvgService) { }


  transformarURL() {

    this._svgService.listaSvg$.subscribe((response: IIconos[]) => {
      if (this.urlsPath && response.length > 0) {
        let url = "";
        if (this.urlIcono) {
          url = this.urlIcono;
        }
        else {
          if (this.urlsPath) {
            const path = this.urlsPath[0].path.split("#");
            url = path[0];
          }
        }
        if (url.includes("blob")) {
          this.url = url;
        }
        else {
          this.url = response.find((x: IIconos) => x.Nombre == url)?.Url || "";
        }
        if (this.urlsPath) {
          this.urlsPath.forEach(async (x, index) => {
            const split = x.path.split('#');
            if (this.urlsPath) {
              this.urlsPath[index].path = this.url + '#' + split[1];
            }
          });
        }
        this.mostrar = true;
      }

    });

  }

}
