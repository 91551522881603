import { environment } from "../environments/environment";

export enum ECarpetas {
  general = "general",
  productos = "productos",
  barras_navegacion = "barras_navegacion",
  modulos = "modulos",
  idiomas = "idiomas",
  vuelos = "vuelos",
  filtros = "filtros",
  documentos = "documentos",
  usuario_viajero = "usuario_viajero",
  flechas_senyales = "flechas_senyales",
  formularios = "formularios",
  toasts = "toasts",
  rentacar = "rent-a-car",
  trenes = "trenes",
  barcos = "barcos"
}

export const Icon = {
  //#region svg standar
  EstrellaEstandar: `${environment.recursos}${environment.baseIconos}${ECarpetas.general}/iconoEstrella.svg`,
  InsigniaEstandar: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_insignia_estandar.svg",
  AsientoAvionEstandar: environment.recursos + environment.baseIconos + ECarpetas.vuelos + "/icono-asiento-vuelo.svg",
  UsuarioEstandar: environment.recursos + environment.baseIconos + ECarpetas.usuario_viajero + "/icono_usuario_estandar.svg",
  SalidaEmergencia: environment.recursos + environment.baseIconos + ECarpetas.vuelos + "/icono-salida-emergencia.svg",
  AsientoAccesibleEstandar: environment.recursos + environment.baseIconos + ECarpetas.vuelos + "/asiento-accesible.svg",
  Bar: environment.recursos + environment.baseIconos + ECarpetas.vuelos + "/icono-bar.svg",
  Comida: environment.recursos + environment.baseIconos + ECarpetas.vuelos + "/icono-comida.svg",
  Aseo: environment.recursos + environment.baseIconos + ECarpetas.vuelos + "/icono-aseos.svg",
  //#endregion
  //#region productos
  Apartamento: environment.recursos + environment.baseIconos + ECarpetas.productos + "/icono_apartamento.svg",
  Hotel: environment.recursos + environment.baseIconos + ECarpetas.productos + "/icono_hotel.svg",
  Tren: environment.recursos + environment.baseIconos + ECarpetas.productos + "/icono_tren.svg",
  RentACar: environment.recursos + environment.baseIconos + ECarpetas.productos + "/icono_rentacar.svg",
  Transfer: environment.recursos + environment.baseIconos + ECarpetas.productos + "/icono_transfer.svg",
  Barco: environment.recursos + environment.baseIconos + ECarpetas.productos + "/icono_barco.svg",
  Aereo: environment.recursos + environment.baseIconos + ECarpetas.productos + "/icono_aereo.svg",
  //#endregion
  //#region barras navegación
  SinFoto: environment.recursos + environment.baseIconos + ECarpetas.barras_navegacion + "/icono_usuario_sin_foto.svg",
  EmpresaSinLogo: environment.recursos + environment.baseIconos + ECarpetas.barras_navegacion + "/icono_empresa_sin_logo.svg",
  ChatAdd: environment.recursos + environment.baseIconos + ECarpetas.barras_navegacion + "/icono_chat_add.svg",
  Chat: environment.recursos + environment.baseIconos + ECarpetas.barras_navegacion + "/icono_chat.svg",
  AlertaConMensaje: environment.recursos + environment.baseIconos + ECarpetas.barras_navegacion + "/icono_alerta_con_mensaje.svg",
  Soporte: environment.recursos + environment.baseIconos + ECarpetas.barras_navegacion + "/icono_soporte.svg",
  PedirAutorizacion: environment.recursos + environment.baseIconos + ECarpetas.barras_navegacion + "/icono_pedir_autorizacion.svg",
  AutorizacionesEnviadas: environment.recursos + environment.baseIconos + ECarpetas.barras_navegacion + "/icono_autorizaciones_enviadas.svg",
  AutorizacionRecibida: environment.recursos + environment.baseIconos + ECarpetas.barras_navegacion + "/icono_autorizaciones_recibidas.svg",
  AlertaSinMensaje: environment.recursos + environment.baseIconos + ECarpetas.barras_navegacion + "/icono_alerta_sin_mensaje.svg",
  Autorizaciones: environment.recursos + environment.baseIconos + ECarpetas.barras_navegacion + "/icono_autorizaciones.svg",
  //#endregion
  //#region modulos
  MisPresupuestos: environment.recursos + environment.baseIconos + ECarpetas.modulos + "/icono_mis_presupuestos.svg",
  PanelControl: environment.recursos + environment.baseIconos + ECarpetas.modulos + "/icono_panel_de_control.svg",
  DashBoard: environment.recursos + environment.baseIconos + ECarpetas.modulos + "/icono_dashboard.svg",
  OtrosGastos: environment.recursos + environment.baseIconos + ECarpetas.modulos + "/icono_otros_gastos.svg",
  Gastos: environment.recursos + environment.baseIconos + ECarpetas.modulos + "/icono_gastos.svg",
  Herramientas: environment.recursos + environment.baseIconos + ECarpetas.modulos + "/icono_herramientas.svg",
  DestinuxGreen: environment.recursos + environment.baseIconos + ECarpetas.modulos + "/icono_destinuxgreen.svg",
  AltaUsuario: environment.recursos + environment.baseIconos + ECarpetas.modulos + "/icono_alta_usuario.svg",
  AltaTarjeta: environment.recursos + environment.baseIconos + ECarpetas.modulos + "/icono_alta_tarjeta.svg",
  Administracion: environment.recursos + environment.baseIconos + ECarpetas.modulos + "/icono_administracion.svg",
  //#endregion
  //#region general
  PuntoDevolucion: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_punto_devolucion.svg",
  PuntoRecogida: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_punto_recogida.svg",
  Editar: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_editar.svg",
  ListaTarjetas: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_lista_tarjetas.svg",
  AgendaViajes: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_agenda_viajes.svg",
  Ubicacion: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_ubicacion.svg",
  Premium: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_premium.svg",
  Lista: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_lista.svg",
  InfoNegativo: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_infonegativo.svg",
  ExclamacionNegativo: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_exclamacion_negativo.svg",
  EstrellaSeleccionada: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_estrella_seleccionada.svg",
  EstrellaEnfocada: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_estrella_enfocada.svg",
  EstrellaActiva: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_estrella_activa.svg",
  DudaNegativa: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_duda_negativo.svg",
  VerMapa: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_ver_en_mapa.svg",
  TrayectoIda: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_trayecto_ida.svg",
  TrayectoVuelta: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_trayecto_vuelta.svg",
  Reloj: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_reloj.svg",
  PinMapa: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_pinmapa.svg",
  Localizador: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_localizador.svg",
  Interrogacion: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_interrogacion.svg",
  Insignia: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_insignia.svg",
  Info: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_info.svg",
  ExpandirSideBar: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_expandir_sidebar.svg",
  Exclamacion: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_exclamacion.svg",
  Enviar: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_enviar.svg",
  Duda: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_duda.svg",
  ContraerSideBar: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_contraer_sidebar.svg",
  Cancelar: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_cancelar.svg",
  CamaHotel: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_cama_hotel.svg",
  Bandera: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_bandera.svg",
  ItemLista: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_item_lista.svg",
  Nuevo: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_nuevo.svg",
  Entrada: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_entrada.svg",
  Salida: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_salida.svg",
  Papelera: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_papelera.svg",
  Noche: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_noche.svg",
  VerDetalles: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_ver_detalles.svg",
  AsignarDatos: environment.recursos + environment.baseIconos + ECarpetas.general + "/icono_asignar_datos.svg",
  //#endregion
  //#region idiomas
  BanderaUk: environment.recursos + environment.baseIconos + ECarpetas.idiomas + "/icono_bandera_uk.svg",
  BanderaIt: environment.recursos + environment.baseIconos + ECarpetas.idiomas + "/icono_bandera_it.svg",
  BanderaPt: environment.recursos + environment.baseIconos + ECarpetas.idiomas + "/icono_bandera_pt.svg",
  BanderaFr: environment.recursos + environment.baseIconos + ECarpetas.idiomas + "/icono_bandera_fr.svg",
  BanderaEs: environment.recursos + environment.baseIconos + ECarpetas.idiomas + "/icono_bandera_es.svg",
  BanderaDe: environment.recursos + environment.baseIconos + ECarpetas.idiomas + "/icono_bandera_de.svg",
  //#endregion
  //#region vuelos
  CambiarVuelos: environment.recursos + environment.baseIconos + ECarpetas.vuelos + "/icono_cambiarvuelo.svg",
  Aeropuerto: environment.recursos + environment.baseIconos + ECarpetas.vuelos + "/icono_aeropuerto.svg",
  Aeropuerto2: environment.recursos + environment.baseIconos + ECarpetas.vuelos + "/icono_aeropuerto_2.svg",
  VueloVuelta: environment.recursos + environment.baseIconos + ECarpetas.vuelos + "/icono_vuelo_vuelta.svg",
  VueloIda: environment.recursos + environment.baseIconos + ECarpetas.vuelos + "/icono_vuelo_ida.svg",
  Mochila: environment.recursos + environment.baseIconos + ECarpetas.vuelos + "/icono_mochila.svg",
  EquipajeFacturada: environment.recursos + environment.baseIconos + ECarpetas.vuelos + "/icono_equipajefacturada.svg",
  EquipajeMano: environment.recursos + environment.baseIconos + ECarpetas.vuelos + "/icono_equipajedemano.svg",
  Despegar: environment.recursos + environment.baseIconos + ECarpetas.vuelos + "/icono_despegar.svg",
  Cancelacion: environment.recursos + environment.baseIconos + ECarpetas.vuelos + "/icono_cancelacion.svg",
  Aterrizar: environment.recursos + environment.baseIconos + ECarpetas.vuelos + "/icono_aterrizar.svg",
  Aeronave: environment.recursos + environment.baseIconos + ECarpetas.vuelos + "/icono_aeronave.svg",
  AsientoAccesible: environment.recursos + environment.baseIconos + ECarpetas.vuelos + "/icono-asiento-accesible.svg",

  //#endregion
  //#region filtros
  AireAcondicionado: environment.recursos + environment.baseIconos + ECarpetas.filtros + "/icono_aire_acondicionado.svg",
  Limpieza: environment.recursos + environment.baseIconos + ECarpetas.filtros + "/icono_limpieza.svg",
  Ascensor: environment.recursos + environment.baseIconos + ECarpetas.filtros + "/icono_ascensor.svg",
  Wifi: environment.recursos + environment.baseIconos + ECarpetas.filtros + "/icono_wifi.svg",
  Piscina: environment.recursos + environment.baseIconos + ECarpetas.filtros + "/icono_piscina.svg",
  Gimnasio: environment.recursos + environment.baseIconos + ECarpetas.filtros + "/icono_gimnasio.svg",
  Filtros: environment.recursos + environment.baseIconos + ECarpetas.filtros + "/icono_filtros.svg",
  CajaFuerte: environment.recursos + environment.baseIconos + ECarpetas.filtros + "/icono_cajafuerte.svg",
  Buscar: environment.recursos + environment.baseIconos + ECarpetas.filtros + "/icono_buscar.svg",
  Aparcamiento: environment.recursos + environment.baseIconos + ECarpetas.filtros + "/icono_aparcamiento.svg",
  //#endregion
  //#region documentos
  Xls: environment.recursos + environment.baseIconos + ECarpetas.documentos + "/icono_xls.svg",
  Doc: environment.recursos + environment.baseIconos + ECarpetas.documentos + "/icono_ver_doc.svg",
  Pdf: environment.recursos + environment.baseIconos + ECarpetas.documentos + "/icono_pdf.svg",
  Billetes: environment.recursos + environment.baseIconos + ECarpetas.documentos + "/icono_billetes.svg",
  //#endregion
  //#region usuario viajero
  Viajero: environment.recursos + environment.baseIconos + ECarpetas.usuario_viajero + "/icono_viajero.svg",
  AgregarViajero: environment.recursos + environment.baseIconos + ECarpetas.usuario_viajero + "/icono_agregar_viajero.svg",
  Viajeros: environment.recursos + environment.baseIconos + ECarpetas.usuario_viajero + "/icono_viajeros.svg",
  Usuario: environment.recursos + environment.baseIconos + ECarpetas.usuario_viajero + "/icono_usuario.svg",
  Solicitante: environment.recursos + environment.baseIconos + ECarpetas.usuario_viajero + "/icono_solicitante.svg",
  Seguro: environment.recursos + environment.baseIconos + ECarpetas.usuario_viajero + "/icono_seguro.svg",
  CuatroViajeros: environment.recursos + environment.baseIconos + ECarpetas.usuario_viajero + "/icono_4viajeros.svg",
  TresViajeros: environment.recursos + environment.baseIconos + ECarpetas.usuario_viajero + "/icono_3viajeros.svg",
  DosViajeros: environment.recursos + environment.baseIconos + ECarpetas.usuario_viajero + "/icono_2viajeros.svg",
  UnViajero: environment.recursos + environment.baseIconos + ECarpetas.usuario_viajero + "/icono_1viajero.svg",
  //#endregion
  //#region flechas y señales
  BotonCerrarTarjeta: environment.recursos + environment.baseIconos + ECarpetas.flechas_senyales + "/icono_boton_cerrar_tarjeta.svg",
  InvertirSentido: environment.recursos + environment.baseIconos + ECarpetas.flechas_senyales + "/icono_invertir_sentido.svg",
  CerrarTarjeta: environment.recursos + environment.baseIconos + ECarpetas.flechas_senyales + "/icono_cerrar_tarjeta.svg",
  Plus: environment.recursos + environment.baseIconos + ECarpetas.flechas_senyales + "/icono_plus.svg",
  Menos: environment.recursos + environment.baseIconos + ECarpetas.flechas_senyales + "/icono_menos.svg",
  FlechaIzquierda: environment.recursos + environment.baseIconos + ECarpetas.flechas_senyales + "/icono_flecha_izquierda.svg",
  FlechaDerecha: environment.recursos + environment.baseIconos + ECarpetas.flechas_senyales + "/icono_flecha_derecha.svg",
  FlechaArriba: environment.recursos + environment.baseIconos + ECarpetas.flechas_senyales + "/icono_flecha_arriba.svg",
  FlechaAbajo: environment.recursos + environment.baseIconos + ECarpetas.flechas_senyales + "/icono_flecha_abajo.svg",
  Descenso: environment.recursos + environment.baseIconos + ECarpetas.flechas_senyales + "/icono_descenso.svg",
  ChevronRight: environment.recursos + environment.baseIconos + ECarpetas.flechas_senyales + "/icono_chevron_right.svg",
  ChevronLeft: environment.recursos + environment.baseIconos + ECarpetas.flechas_senyales + "/icono_chevron_left.svg",
  ChevronDown: environment.recursos + environment.baseIconos + ECarpetas.flechas_senyales + "/icono_chevron_down.svg",
  CerrarModal: environment.recursos + environment.baseIconos + ECarpetas.flechas_senyales + "/icono_cerrar_modal.svg",
  Ascenso: environment.recursos + environment.baseIconos + ECarpetas.flechas_senyales + "/icono_ascenso.svg",
  ChevronUp: environment.recursos + environment.baseIconos + ECarpetas.flechas_senyales + "/icono_chevron_up.svg",
  NoPermitido: environment.recursos + environment.baseIconos + ECarpetas.flechas_senyales + "/icono_no_permitido.svg",
  //#endregion
  //#region fomulario
  CheckBoxActivo: environment.recursos + environment.baseIconos + ECarpetas.formularios + "/icono_checkbox_activo.svg",
  CheckBoxSeleccionadoPrimario: environment.recursos + environment.baseIconos + ECarpetas.formularios + "/icono_checkbox_seleccionado_primario.svg",
  Contrasenya: environment.recursos + environment.baseIconos + ECarpetas.formularios + "/icono_contrasena.svg",
  OcultarContrasenya: environment.recursos + environment.baseIconos + ECarpetas.formularios + "/icono_ocultar_ojo.svg",
  VerContrasenya: environment.recursos + environment.baseIconos + ECarpetas.formularios + "/icono_ojo.svg",
  CheckBoxDesactivado: environment.recursos + environment.baseIconos + ECarpetas.formularios + "/icono_checkbox_desactivado.svg",
  RadioActivo: environment.recursos + environment.baseIconos + ECarpetas.formularios + "/icono_radio_activo_primario.svg",
  RadioSeleccionado: environment.recursos + environment.baseIconos + ECarpetas.formularios + "/icono_radio_seleccionado_primario.svg",
  RadioSeleccionadoSecundario: environment.recursos + environment.baseIconos + ECarpetas.formularios + "/icono_radio_seleccionado_secundario.svg",
  RadioDesactivado: environment.recursos + environment.baseIconos + ECarpetas.formularios + "/icono_radio_desactivado.svg",
  CheckBoxSenyalado: environment.recursos + environment.baseIconos + ECarpetas.formularios + "/icono_checkbox_senyalado.svg",
  //#endregion
  //#region toast
  ToastAlerta: environment.recursos + environment.baseIconos + ECarpetas.toasts + "/icono_toast_alerta.svg",
  ToastError: environment.recursos + environment.baseIconos + ECarpetas.toasts + "/icono_toast_error.svg",
  ToastExito: environment.recursos + environment.baseIconos + ECarpetas.toasts + "/icono_toast_exito.svg",
  ToastInfo: environment.recursos + environment.baseIconos + ECarpetas.toasts + "/icono_toast_info.svg",
  //#endregion
  //#region rent a car
  Oficina: environment.recursos + environment.baseIconos + ECarpetas.rentacar + "/icono_oficina.svg",
  Puerta: environment.recursos + environment.baseIconos + ECarpetas.rentacar + "/icono_puerta.svg",
  AireAcondicionadoRent: environment.recursos + environment.baseIconos + ECarpetas.rentacar + "/icono_temperatura_2.svg",
  Transmision: environment.recursos + environment.baseIconos + ECarpetas.rentacar + "/icono_transmision.svg",
  //#endregion
  //#region trenes
  Anden: environment.recursos + environment.baseIconos + ECarpetas.trenes + "/icono_anden.svg",
  PerdidaTren: environment.recursos + environment.baseIconos + ECarpetas.trenes + "/icono_perdida_de_tren.svg",
  TrenIda: environment.recursos + environment.baseIconos + ECarpetas.trenes + "/icono_tren_ida.svg",
  TrenVuelta: environment.recursos + environment.baseIconos + ECarpetas.trenes + "/icono_tren_vuelta.svg",
  CambioViajero: environment.recursos + environment.baseIconos + ECarpetas.trenes + "/icono_cambio_viajero.svg",
  UnAsiento: environment.recursos + environment.baseIconos + ECarpetas.trenes + "/icono_1asiento.svg",
  DosAsiento: environment.recursos + environment.baseIconos + ECarpetas.trenes + "/icono_2asientos.svg",
  //#endregion
  //#region barcos
  Ferry: environment.recursos + environment.baseIconos + ECarpetas.barcos + "/icono_ferry.svg",
  BarcoIda: environment.recursos + environment.baseIconos + ECarpetas.barcos + "/icono_barco_ida.svg",
  BarcoVuelta: environment.recursos + environment.baseIconos + ECarpetas.barcos + "/icono_barco_vuelta.svg",
  //#endregion
}

export const iconos = [
  //#region antiguos
  {
    "url": Icon.EstrellaEstandar,
    "estandar": true
  },
  {
    "url": Icon.InsigniaEstandar,
    "estandar": true
  },
  {
    "url": Icon.Apartamento,
    "estandar": false
  },
  {
    "url": Icon.Hotel,
    "estandar": false
  },
  {
    "url": Icon.Tren,
    "estandar": false
  },
  {
    "url": Icon.RentACar,
    "estandar": false
  },
  {
    "url": Icon.Transfer,
    "estandar": false
  },
  {
    "url": Icon.Barco,
    "estandar": false
  },
  {
    "url": Icon.Aereo,
    "estandar": false
  },
  {
    "url": Icon.SinFoto,
    "estandar": false
  },
  {
    "url": Icon.EmpresaSinLogo,
    "estandar": false
  },
  {
    "url": Icon.ChatAdd,
    "estandar": false
  },
  {
    "url": Icon.Chat,
    "estandar": false
  },
  {
    "url": Icon.AlertaConMensaje,
    "estandar": false
  },
  {
    "url": Icon.Soporte,
    "estandar": false
  },
  {
    "url": Icon.PedirAutorizacion,
    "estandar": false
  },
  {
    "url": Icon.AutorizacionesEnviadas,
    "estandar": false
  },
  {
    "url": Icon.AutorizacionRecibida,
    "estandar": false
  },
  {
    "url": Icon.AlertaSinMensaje,
    "estandar": false
  },
  {
    "url": Icon.Autorizaciones,
    "estandar": false
  },
  {
    "url": Icon.MisPresupuestos,
    "estandar": false
  },
  {
    "url": Icon.PanelControl,
    "estandar": false
  },
  {
    "url": Icon.DashBoard,
    "estandar": false
  },
  {
    "url": Icon.AgendaViajes,
    "estandar": false
  },
  {
    "url": Icon.OtrosGastos,
    "estandar": false
  },
  {
    "url": Icon.Gastos,
    "estandar": false
  },
  {
    "url": Icon.Herramientas,
    "estandar": false
  },
  {
    "url": Icon.DestinuxGreen,
    "estandar": false
  },
  {
    "url": Icon.AltaUsuario,
    "estandar": false
  },
  {
    "url": Icon.AltaTarjeta,
    "estandar": false
  },
  {
    "url": Icon.Administracion,
    "estandar": false
  },
  {
    "url": Icon.PuntoDevolucion,
    "estandar": false
  },
  {
    "url": Icon.PuntoRecogida,
    "estandar": false
  },
  {
    "url": Icon.Editar,
    "estandar": false
  },
  {
    "url": Icon.ListaTarjetas,
    "estandar": false
  },
  {
    "url": Icon.AgendaViajes,
    "estandar": false
  },
  {
    "url": Icon.Ubicacion,
    "estandar": false
  },
  {
    "url": Icon.Premium,
    "estandar": false
  },
  {
    "url": Icon.Lista,
    "estandar": false
  },
  {
    "url": Icon.InfoNegativo,
    "estandar": false
  },
  {
    "url": Icon.ExclamacionNegativo,
    "estandar": false
  },
  {
    "url": Icon.EstrellaSeleccionada,
    "estandar": false
  },
  {
    "url": Icon.EstrellaEnfocada,
    "estandar": false
  },
  {
    "url": Icon.EstrellaActiva,
    "estandar": false
  },
  {
    "url": Icon.DudaNegativa,
    "estandar": false
  },
  {
    "url": Icon.VerMapa,
    "estandar": false
  },
  {
    "url": Icon.TrayectoIda,
    "estandar": false
  },
  {
    "url": Icon.TrayectoVuelta,
    "estandar": false
  },
  {
    "url": Icon.Reloj,
    "estandar": false
  },
  {
    "url": Icon.PinMapa,
    "estandar": false
  },
  {
    "url": Icon.Localizador,
    "estandar": false
  },
  {
    "url": Icon.InvertirSentido,
    "estandar": false
  },
  {
    "url": Icon.Interrogacion,
    "estandar": false
  },
  {
    "url": Icon.Insignia,
    "estandar": false
  },
  {
    "url": Icon.Info,
    "estandar": false
  },
  {
    "url": Icon.ExpandirSideBar,
    "estandar": false
  },
  {
    "url": Icon.Exclamacion,
    "estandar": false
  },
  {
    "url": Icon.Enviar,
    "estandar": false
  },
  {
    "url": Icon.Duda,
    "estandar": false
  },
  {
    "url": Icon.ContraerSideBar,
    "estandar": false
  },
  {
    "url": Icon.Cancelar,
    "estandar": false
  },
  {
    "url": Icon.CamaHotel,
    "estandar": false
  },
  {
    "url": Icon.Bandera,
    "estandar": false
  },
  {
    "url": Icon.Aparcamiento,
    "estandar": false
  },
  {
    "url": Icon.BanderaUk,
    "estandar": false
  },
  {
    "url": Icon.BanderaIt,
    "estandar": false
  },
  {
    "url": Icon.BanderaPt,
    "estandar": false
  },
  {
    "url": Icon.BanderaFr,
    "estandar": false
  },
  {
    "url": Icon.BanderaEs,
    "estandar": false
  },
  {
    "url": Icon.BanderaDe,
    "estandar": false
  },
  {
    "url": Icon.CambiarVuelos,
    "estandar": false
  },
  {
    "url": Icon.Aeropuerto,
    "estandar": false
  },
  {
    "url": Icon.Aeropuerto2,
    "estandar": false
  },
  {
    "url": Icon.VueloVuelta,
    "estandar": false
  },
  {
    "url": Icon.VueloIda,
    "estandar": false
  },
  {
    "url": Icon.Mochila,
    "estandar": false
  },
  {
    "url": Icon.EquipajeFacturada,
    "estandar": false
  },
  {
    "url": Icon.EquipajeMano,
    "estandar": false
  },
  {
    "url": Icon.Despegar,
    "estandar": false
  },
  {
    "url": Icon.Cancelacion,
    "estandar": false
  },
  {
    "url": Icon.Aterrizar,
    "estandar": false
  },
  {
    "url": Icon.Aeronave,
    "estandar": false
  },
  {
    "url": Icon.AireAcondicionado,
    "estandar": false
  },
  {
    "url": Icon.Limpieza,
    "estandar": false
  },
  {
    "url": Icon.Ascensor,
    "estandar": false
  },
  {
    "url": Icon.Wifi,
    "estandar": false
  },
  {
    "url": Icon.Piscina,
    "estandar": false
  },
  {
    "url": Icon.Gimnasio,
    "estandar": false
  },
  {
    "url": Icon.Filtros,
    "estandar": false
  },
  {
    "url": Icon.CajaFuerte,
    "estandar": false
  },
  {
    "url": Icon.Buscar,
    "estandar": false
  },
  {
    "url": Icon.Xls,
    "estandar": false
  },
  {
    "url": Icon.Doc,
    "estandar": false
  },
  {
    "url": Icon.Pdf,
    "estandar": false
  },
  {
    "url": Icon.Billetes,
    "estandar": false
  },
  {
    "url": Icon.Viajero,
    "estandar": false
  },
  {
    "url": Icon.AgregarViajero,
    "estandar": false
  },
  {
    "url": Icon.Viajeros,
    "estandar": false
  },
  {
    "url": Icon.Usuario,
    "estandar": false
  },
  {
    "url": Icon.Solicitante,
    "estandar": false
  },
  {
    "url": Icon.Seguro,
    "estandar": false
  },
  {
    "url": Icon.CuatroViajeros,
    "estandar": false
  },
  {
    "url": Icon.TresViajeros,
    "estandar": false
  },
  {
    "url": Icon.DosViajeros,
    "estandar": false
  },
  {
    "url": Icon.UnViajero,
    "estandar": false
  },
  {
    "url": Icon.BotonCerrarTarjeta,
    "estandar": false
  },
  {
    "url": Icon.CerrarTarjeta,
    "estandar": false
  },
  {
    "url": Icon.Plus,
    "estandar": false
  },
  {
    "url": Icon.Menos,
    "estandar": false
  },
  {
    "url": Icon.FlechaIzquierda,
    "estandar": false
  },
  {
    "url": Icon.FlechaDerecha,
    "estandar": false
  },
  {
    "url": Icon.FlechaArriba,
    "estandar": false
  },
  {
    "url": Icon.FlechaAbajo,
    "estandar": false
  },
  {
    "url": Icon.Descenso,
    "estandar": false
  },
  {
    "url": Icon.ChevronRight,
    "estandar": false
  },
  {
    "url": Icon.ChevronLeft,
    "estandar": false
  },
  {
    "url": Icon.ChevronDown,
    "estandar": false
  },
  {
    "url": Icon.CerrarModal,
    "estandar": false
  },
  {
    "url": Icon.Ascenso,
    "estandar": false
  },
  {
    "url": Icon.ChevronUp,
    "estandar": false
  },
  {
    "url": Icon.NoPermitido,
    "estandar": false
  },
  {
    "url": Icon.CheckBoxDesactivado,
    "estandar": false
  },
  {
    "url": Icon.RadioActivo,
    "estandar": false
  },
  {
    "url": Icon.CheckBoxActivo,
    "estandar": false
  },
  {
    "url": Icon.VerContrasenya,
    "estandar": false
  },
  {
    "url": Icon.OcultarContrasenya,
    "estandar": false
  },
  {
    "url": Icon.Contrasenya,
    "estandar": false
  },
  {
    "url": Icon.CheckBoxSeleccionadoPrimario,
    "estandar": false
  },
  {
    "url": Icon.RadioSeleccionado,
    "estandar": false
  },
  {
    "url": Icon.RadioSeleccionadoSecundario,
    "estandar": false
  },
  {
    "url": Icon.RadioDesactivado,
    "estandar": false
  },
  {
    "url": Icon.CheckBoxSenyalado,
    "estandar": false
  },
  {
    "url": Icon.ItemLista,
    "estandar": false
  },
  {
    "url": Icon.ToastAlerta,
    "estandar": false
  }
  ,
  {
    "url": Icon.ToastError,
    "estandar": false
  },
  {
    "url": Icon.ToastExito,
    "estandar": false
  },
  {
    "url": Icon.ToastInfo,
    "estandar": false
  },
  {
    "url": Icon.Oficina,
    "estandar": false
  },
  {
    "url": Icon.Puerta,
    "estandar": false
  },
  {
    "url": Icon.AireAcondicionadoRent,
    "estandar": false
  },
  {
    "url": Icon.Transmision,
    "estandar": false
  },
  {
    "url": Icon.Anden,
    "estandar": false
  },
  {
    "url": Icon.PerdidaTren,
    "estandar": false
  },
  {
    "url": Icon.TrenIda,
    "estandar": false
  },
  {
    "url": Icon.TrenVuelta,
    "estandar": false
  },
  {
    "url": Icon.CambioViajero,
    "estandar": false
  },
  {
    "url": Icon.UnAsiento,
    "estandar": false
  },
  {
    "url": Icon.DosAsiento,
    "estandar": false
  },
  {
    "url": Icon.Nuevo,
    "estandar": false
  },
  {
    "url": Icon.Entrada,
    "estandar": false
  },
  {
    "url": Icon.Salida,
    "estandar": false
  },
  {
    "url": Icon.Papelera,
    "estandar": false
  },
  {
    "url": Icon.Noche,
    "estandar": false
  },
  {
    "url": Icon.AsignarDatos,
    "estandar": false
  },
  //#endregion
  //#region nuevos
  {
    "url": Icon.VerDetalles,
    "estandar": false
  },
  {
    "url": Icon.Ferry,
    "estandar": false
  },
  {
    "url": Icon.BarcoIda,
    "estandar": false
  },
  {
    "url": Icon.BarcoVuelta,
    "estandar": false
  },
  {
    "url": Icon.AsientoAvionEstandar,
    "estandar": true
  },
  {
    "url": Icon.AsientoAccesible,
    "estandar": false
  },
  {
    "url": Icon.UsuarioEstandar,
    "estandar": true
  }
  ,
  {
    "url": Icon.Bar,
    "estandar": true
  },
  {
    "url": Icon.Comida,
    "estandar": true
  }
  ,
  {
    "url": Icon.Aseo,
    "estandar": true
  },
  {
    "url": Icon.SalidaEmergencia,
    "estandar": true
  },
  {
    "url": Icon.AsientoAccesibleEstandar,
    "estandar": true
  }
  //#endregion
]
