import { Component, HostBinding, Input, OnInit, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import {
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { ETipoClase } from '@destinux/destinux-enums';
import { ControlValue } from '../../../../../libs/destinux-components/src/utiles/ControlValue';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '@destinux-components/environment';

@Component({
  selector: 'destinux-checkbox-prime',
  standalone: true,
  imports: [CommonModule, CheckboxModule, FormsModule, TranslateModule],
  templateUrl: './checkbox-prime.component.html',
  styleUrls: ['./checkbox-prime.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxPrimeComponent),
      multi: true,
    },
  ],
})
export class CheckboxPrimeComponent extends ControlValue implements OnInit {
  //#region variables
  private _tamanyo!: string | null;
  //#endregion

  //#region inputs
  /** nombre que tendrá todo el grupo de checkbox */
  @Input({ required: true }) nombre: string = '';
  /** indica el tipo de diseño que tendrá el checkbox */
  @Input() tipo: ETipoClase = ETipoClase.Primario;
  /** para deshabilitar el checkbox */
  @Input() disabled: boolean = false;
  /** son los valores (items) que tendrá el checkbox para su selección */
  @Input({ required: true }) valores: string[] = [];
  /** para saber si el valor a devolver es en booleano o en string. true: boolean, false: string[] */
  @Input() binario: boolean = false;
  /** indica si el urlRecursos a los iconos es el del environment de produccion
   * opcional
   */
  @Input() esProd: boolean = false;
  /** indica el texto a traducir, de ser necesario */
  @Input() traducir: string | null = null;
  /** para mostrar la lista en vertical u horizontal. true: vertical, false: horizontal */
  @Input() vertical: boolean = true;
  /** para agregarle una clase al checkbox  */
  @Input() classCheck: string = '';
  /** permite crear un checkbox pero que solo se pueda seleccionar un elemento de la lista. true: solo un elemento, false: multiselect  */
  @Input() unitario: boolean = true;
  /** permite dar un tamanyo a la caja del checkbox */
  @Input() tamanyo: string = '22px'; // tamanyo que tiene el box del componente primeNg (alto = ancho)
  /** permite dar un tamanyo a la caja del label */
  @Input() paddingLabel: string = 'g-padding-12';
  //#endregion

  @HostBinding('style.--background-check') backgroundCheck = `url(${environment.recursos}${environment.baseIconosOriginales}formularios/formularios/icono_checkbox_desactivado.svg)`;
  @HostBinding('style.--checkboxActivo') check = `url(${environment.recursos}${environment.baseIconosOriginales}formularios/icono_checkbox_activo.svg)`;
  @HostBinding('style.--checkboxSeleccionarioPrimario') checkboxSeleccionarioPrimario = `url(${environment.recursos}${environment.baseIconosOriginales}formularios/icono_checkbox_seleccionado_primario.svg)`;
  @HostBinding('style.--checkboxSeleccionarioSecundario') checkboxSeleccionarioSecundario = `url(${environment.recursos}${environment.baseIconosOriginales}formularios/icono_checkbox_seleccionado_secundario.svg)`;
  @HostBinding('style.--tamanyo_box') tamanyo_box = this.tamanyo;

  //#region constructor
  constructor() {
    super();
    this.value = [];
  }
  //#endregion

  ngOnInit(){
    this.tamanyo_box = this.tamanyo;
  }

  //#region metodos usados en html
  getClaseCheck(valor: string): string {
    if (
      this.classCheck != '' &&
      this.value &&
      this.binario == false &&
      this.value.length > 0 &&
      this.value.includes(valor)
    ) {
      return this.classCheck;
    } else {
      return '';
    }
  }
  override EscribirValor(): void {
    if (this.unitario) {
      this.hndCambioOrden();
    }
    this.onTouch();
    // console.log(this.value);
    this.onChange(this.value);

  }
  //#endregion

  //#region metodos


  public hndCambioOrden() {
    // console.log(this.value);
    if (this.value.length > 1) {
      this.value.splice(0, 1);
    }
  }

  //#endregion
}
